<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-form ref="form" :model="form" label-width="90px">
					<el-row :gutter="10">
						<el-col :span="6" :xs="24">
							<el-form-item label="uuid"><el-input v-model="form.uuid" clearable placeholder="请输入uuid"></el-input></el-form-item>
						</el-col>
						<el-col :span="6" :xs="24" class="el"><el-button type="primary" @click="jouShare">搜索</el-button></el-col>
					</el-row>
				</el-form>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" style="width: 100%" border stripe>
					<el-table-column prop="uuid" label="uuid"></el-table-column>
					<el-table-column prop="blackReason" label="异常信息"></el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'abnormalEquipmen',
	data() {
		return {
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			form: {
				uuid: ''
			}
		};
	},
	created() {
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 获取播完激励视频列表
		this.abnormalEquipmen();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 获取播完激励视频列表
				this.abnormalEquipmen();
			}
		}
	},
	methods: {
		// 获取播完激励视频列表
		abnormalEquipmen() {
			this.serloading = true;
			this.$HTTP.post(
				'deviceBlack/query',
				{
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					uuid: this.form.uuid
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 搜索
		jouShare() {
			this.currentPage = 1;
			// 获取播完激励视频列表
			this.abnormalEquipmen();
		},

		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取播完激励视频列表
			this.abnormalEquipmen();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取播完激励视频列表
			this.abnormalEquipmen();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
